const inputs = [
    {
        id: 2,
        name: 'hoten',
        type: 'text',
        placeholder: 'Nguyễn Văn A',
        label: 'Họ và tên',
        required: true,
    },
    {
        id: 3,
        name: 'ngaysinh',
        type: 'date',
        placeholder: 'Nguyễn Văn A',
        label: 'Ngày sinh',
        required: true,
    },
    {
        id: 4,
        name: 'gioitinh',
        type: 'radio',
        value: '0',
        label: 'Nam',
    },
    {
        id: 5,
        name: 'gioitinh',
        type: 'radio',
        value: '1',
        label: 'Nữ',
    },
    {
        id: 6,
        name: 'dantoc',
        type: 'text',
        placeholder: 'Kinh',
        label: 'Dân tộc',
        required: true,
    },
    {
        id: 7,
        name: 'tongiao',
        type: 'text',
        placeholder: 'Không',
        label: 'Tôn giáo',
        required: true,
    },
    {
        id: 8,
        name: 'dienthoai',
        type: 'text',
        placeholder: '0909080706',
        label: 'Số điện thoại',
        required: true,
    },
    {
        id: 9,
        name: 'quequan',
        type: 'text',
        placeholder: 'TP. Hồ Chí Minh',
        label: 'Quê quán',
        required: true,
    },
    {
        id: 10,
        name: 'chihoi',
        type: 'text',
        placeholder: 'CN20CLCA',
        label: 'Chi hội',
        required: true,
    },
    {
        id: 11,
        name: 'donvi',
        type: 'text',
        placeholder: 'Viện Đào tạo Chất lượng cao',
        label: 'Liên chi hội',
        required: true,
    },
];

export default inputs;
